import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import VueAceEdit from 'vue-ace-edit';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/plugins/analytics';
import '@/plugins/font-awesome';
import '@/plugins/sentry';

Vue.use(VueAceEdit);
Vue.use(BootstrapVue);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    created() {
        store.dispatch('toolbox/fetchUser');
    },
    render: h => h(App),
}).$mount('#app');
