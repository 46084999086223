import alerts from '@imt/vue-admin-menus/src/store/alerts';
import toolbox from '@imt/vue-toolbox/src/store';
import utils, {
    axiosWithAuth,
    logoutUser,
} from '@imt/vue-toolbox/src/utils';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
export const axios = axiosWithAuth('/api/v2');

export const getters = {
    ratingFields() {
        return [
            {key: 'id', label: 'ID', sortable: true},
            {key: 'createdAt', label: 'Created At', sortable: true},
            {key: 'policySystem', label: 'Policy System', sortable: true},
        ];
    },
    recordTypeOptions() {
        return [
            {text: 'Service', value: 'service'},
            {text: 'Rating', value: 'rating'}
        ];
    },
    serviceFields() {
        return [
            {key: 'company', label: 'Company', sortable: true},
            {key: 'vendor', label: 'Vendor', sortable: true},
            {key: 'source', label: 'Source', sortable: true},
            {key: 'service', label: 'Service', sortable: true},
            {key: 'date', label: 'Date', sortable: true},
        ];
    },
};

export const actions = {
    async fetchDistinct(context, {company, vendor, source, service, requestMethod, policySystem}) {
        let response = await axios.get(
            '/distinct/', {
                params: {
                    company: company,
                    service: service,
                    vendor: vendor,
                    source: source,
                    requestMethod: requestMethod,
                    policySystem: policySystem
                }
            }
        );

        return response.data;
    },
    async fetchRecordById(context, {id, isService, fromSearch}) {
        const response = await axios.get(`/${isService ? 'services' : 'ratings'}/${id}/`);

        if (fromSearch && response.data) {
            context.commit('SET_DATA', {type: 'results', data: [utils.dataFormatter.deserialize(response.data)]});
            context.commit('SET_DATA', {type: 'count', data: 1});
        } else if (!fromSearch && response.data) {
            context.commit('SET_DATA', {
                type: 'selectedResult',
                data: utils.dataFormatter.deserialize(response.data)
            });
        }
    },
    async fetchSearchResults(context, baseURL) {
        let response = await axios.get(baseURL),
            results = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_DATA', {type: 'results', data: results});
        context.commit('SET_DATA', {type: 'count', data: response.data.meta.pagination.count || 0});
    },
    logout(context) {
        logoutUser();
        context.commit('SET_AUTH_TOKEN', '');
        window.location = `${process.env.VUE_APP_ADMIN_URL_USERS}`;
    },
    resetPassword() {
        window.location = `${process.env.VUE_APP_ADMIN_URL_USERS}/change-password`;
    },
};

export const mutations = {
    SET_AUTH_TOKEN(state, token) {
        state.authToken = token;
        axios.defaults.headers.Authorization = token;
    },
    SET_DATA(state, {type, data}) {
        state[type] = data;
    },
    CLEAR_SEARCH_FIELDS(state) {
        state.searchFields = {};
    },
    SET_SEARCH_FIELDS(state, searchField) {
        if ((typeof searchField.value !== 'boolean' && !searchField.value) || (Array.isArray(searchField.value) && !searchField.value.length)) {
            Vue.delete(state.searchFields, searchField.key);
        } else {
            Vue.set(state.searchFields, searchField.key, searchField.value);
        }
    }
};

export const state = () => {
    return {
        count: 0,
        initialSearchSubmitted: false,
        loading: false,
        results: [],
        searchFields: {},
        selectedResult: {},
        submitted: false,
    };
};

export default new Vuex.Store({
    modules: {
        alerts,
        toolbox,
    },
    actions,
    getters,
    mutations,
    state: state(),
});
