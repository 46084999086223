<template>
    <div id="app">
        <h3
            v-if="!permissionsLoaded"
            class="service-tracker-loading-component text-muted"
        >
            loading...
        </h3>
        <RouterView v-else-if="permissionsLoaded && userHasPermission" />
        <Forbidden v-else />
    </div>
</template>

<script>
    import Forbidden from '@imt/vue-kit-car/src/components/errors/Forbidden.vue';
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';
    import {mapState} from 'vuex';

    export default {
        components: {
            Forbidden,
        },
        mixins: [
            authMixin,
        ],
        computed: {
            permissionsLoaded() {
                return !!this.user.id;
            },
            userHasPermission() {
                return this.permissions.service_tracker_access;
            },
            ...mapState('toolbox', [
                'permissions',
                'user',
            ]),
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"
    @import "~@imt/vue-kit-car/src/sass/modes/light/colors"

    .page-item.active .page-link
        z-index: 1
        color: $pagination-color-active !important
        background-color: $pagination-background-active !important

    .page-link
        background-color: $pagination-background !important
        border-color: $pagination-border-color !important
        color: $pagination-color !important

    .service-tracker-loading-component
        display: flex
        align-items: center
        justify-content: center
        height: 100vh
        width: 100vw
</style>
