import {UUID_REGEX} from '@imt/vue-toolbox/src/utils/index';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: {
                name: 'service',
            },
        },
        {
            path: '/admin',
            name: 'admin',
            component: () => import(/* webpackChunkName: "Home" */ '@/views/STHome.vue'),
            redirect: {
                name: 'service',
            },
            children: [
                {
                    path: 'service',
                    name: 'service',
                    component: () => import(/* webpackChunkName: "ServiceSearch" */ '@/components/STServiceSearch.vue'),
                    children: [
                        {
                            path: `details/:serviceId(${UUID_REGEX})`,
                            name: 'service-details',
                            component: () => import(/* webpackChunkName: "ServiceDetailsModal" */ '@/components/STServiceDetailsModal.vue'),
                        },
                    ],
                },
                {
                    path: 'rating',
                    name: 'rating',
                    component: () => import(/* webpackChunkName: "RatingSearch" */ '@/components/STRatingSearch.vue'),
                    children: [
                        {
                            path: `details/:ratingId(${UUID_REGEX})`,
                            name: 'rating-details',
                            component: () => import(/* webpackChunkName: "RatingDetailsModal" */ '@/components/STRatingDetailsModal.vue'),
                        },
                    ],
                },
            ],
        },
        {
            path: '*',
            name: '404',
            component: () => import(/* webpackChunkName: "NotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
        },
    ],
});
